.signup__container {
    background-image: url('../../assets/SurgingGenerator.png');
    background-size: cover;
}

.signup__form {
    width: 600px;
    height: 500px;
    margin-left: calc(50vw - 300px);
}

#play {
    padding-top: 30vh;
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 
    .signup__container {
        background-position: 50%;
    }
    
    .signup__form {

    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 
    .signup__container {
        background-position: 50%;
    }
    
    .signup__form {
        width: 350px;
        height: 500px;
        margin-left: calc(50vw - 175px);
    }

    #play {
        padding-top: 10vh;
    }
}

/* ------------ MEDIA QUERIES (SHORTER DISPLAYS) ------------- */
@media screen and (max-height: 850px) { 
    .signup__container {
        background-position: 50%;
    }
    
    .signup__form {
        width: 350px;
        height: 500px;
        margin-left: calc(50vw - 175px);
    }

    #play {
        padding-top: 2vh;
    }
}