.about__container {
    background-image: url('../../assets/AlgaeGenerator.jpg');
    background-size: cover;
}

.about__textbox {
    position: relative;
    width: 50vw;
    margin-left: 25vw;
    top: 50vh;
    height: fit-content;
    padding: 2rem 3rem;
    background-color: rgba(15, 10, 10, 0.829);
    border-radius: 1.5rem;
    text-align: center;
    border: 3px solid var(--color-white);
}

.about__textbox > p {
    font-size: 1.5rem;
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 
    .about__container {
        background-position: 40%;
    }
    
    
    .about__textbox {
        width: 80vw;
        margin-left: 10vw;
        padding: 2rem;
    }
    
    .about__textbox > p {
        font-size: 1.5rem;
    }

    .about__textbox > h1 {
        font-size: 2.4rem;
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 
    .about__container {
        background-position: 40%;
    }
    
    .about__textbox {
        width: 80vw;
        margin-left: 10vw;
        padding: 1rem;
        top: 40vh;
    }
    
    .about__textbox > p {
        font-size: 1.2rem;
    }

    .about__textbox > h1 {
        font-size: 2rem;
    }
}

@media screen and (max-height: 600px) { 
    
    .about__textbox {
        padding: 1rem;
        top: 30vh;
    }
    
    .about__textbox > p {
        font-size: 0.8rem;
    }

    .about__textbox > h1 {
        font-size: 1.2rem;
    }
}

/* ------------ MEDIA QUERIES (SHORTER DISPLAYS) ------------- */
@media screen and (max-height: 850px) { 
    .about__container {
        background-position: 40%;
    }
    
    .about__textbox {
        width: 80vw;
        margin-left: 10vw;
        padding: 1rem;
        top: 40vh;
    }
    
    .about__textbox > p {
        font-size: 1.2rem;
    }

    .about__textbox > h1 {
        font-size: 2rem;
    }
}

@media screen and (max-height: 600px) { 
    
    .about__textbox {
        padding: 1rem;
        top: 30vh;
    }
    
    .about__textbox > p {
        font-size: 0.8rem;
    }

    .about__textbox > h1 {
        font-size: 1.2rem;
    }
}