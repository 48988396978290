.backstop__container {
    background-image: url('../../assets/Pipeline.png');
    background-size: cover;
    background-position: 50%;
}

.backstop__text {
    padding-top: 40vh;
    width:fit-content;
    margin-left: auto;
    margin-right: auto;
}

.backstop__text > h1 {
    font-size: 9rem;
    color: aquamarine;
    text-align: center;
}

.backstop__copyright {
    font-size: 16px;
    padding-top: 30vh;
    width:fit-content;
    margin-left: auto;
    margin-right: auto;
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 
    .backstop__text > h1 {
        font-size: 4rem;
        color: aquamarine;
        text-align: center;
    }
}

/* ------------ MEDIA QUERIES (SHORTER DISPLAYS) ------------- */
@media screen and (max-height: 850px) { 
    .backstop__text > h1 {
        font-size: 4rem;
        color: aquamarine;
        text-align: center;
    }
}