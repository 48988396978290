.devlog-slider-active {
    display: inline-block;
    margin: 0;
    height: 100vh;
}

.devlog-slider-inactive {
    display: none;
}

.devlog__text {
    padding: 2rem 2rem 0 2rem;
}

.devlog__text > h4 {
    margin-bottom: .5rem;
    text-align: center;
}

.devlog__date {
    text-align: right;
    font-size: 1rem;
    padding-right: 2rem;
    margin-bottom: 1rem;
}

.devlog-slides {
    overflow-y: auto;
    width: 49vw;
    height: 60vh;
}

.devlog__content {
    position: relative;
    color: var(--color-white);
    font-size: 30px;
    height: 70vh;
    width: 50vw;
    margin-left: 25vw;
    margin-top: 2rem;
    background-color: var(--color-blue);
    padding: 1.2rem;
    border-radius: 1.2rem;
    border: 3px solid var(--color-white);
}

.devlog__heading {
    width: fit-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5vh;
}

.devlog__heading > h2 {
    background-color: var(--color-blue);
    padding: 2rem 4rem;
    border-radius: 1.2rem;
    border: 3px solid var(--color-white);
}

/* ARROWS */

.devlog-prev,
.devlog-next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 450%;
    left: calc(20vw - 100px);
    width: 70px;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
}

.devlog-prev:hover, .devlog-next:hover {
    color: var(--color-bg);
    background-color: var(--color-white);
    transition: all 0.5s ease-in;
}

.devlog-next {
    left: calc(80vw + 70px);
    border-radius: 5px;
}

/* DOTS */

.devlog-all-dots {
    width: 90%;
    height: 50px;
    margin-left: 5%;
    position: relative;
    display: flex;
    top: 1rem;
    justify-content: center;
    z-index: 200;
}

.devlog-all-dots > span {
    margin-left: 15px;
}

.devlog-dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
    border: 2px solid rgba(48, 48, 48, 0.534);
}

.devlog-active-dot, 
.devlog-dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 
    .devlog-next {
        left: 90vw;
    }

    .devlog-prev {
        left: 1vw;
    }

    .devlog__content {
        width: 80vw;
        margin-left: 10vw;
        height: 70vh;
        padding: 0;
    }

    .devlog__text {
        width: 78vw;
        height: 60vh;
        padding: 2rem;
        margin: 0;
    }

    .devlog-slides {
        overflow-y: auto;
        width: 78vw;
        height: 60vh;
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 

    .devlog__heading > h2 {
        font-size: 1.6rem;
        padding: 1.5rem;
    }

    .devlog__content {
        width: 80vw;
        margin-left: 10vw;
        height: 70vh;
        padding: 0;
    }

    .devlog__text {
        width: 74vw;
        height: 60vh;
        padding: 1rem;
        margin: 0;
    }

    .devlog__text p {
        font-size: 1.2rem;
    }

    .devlog__text h4 {
        font-size: 1.6rem;
    }

    .devlog__date {
        padding-right: 0;
    }

    .devlog__date p {
        font-size: 0.8rem;
    }

    .devlog-slides {
        overflow-y: auto;
        width: 78vw;
        height: 60vh;
    }

    .devlog-next, .devlog-prev {
        top: 450%;
    }
    
    .devlog-next {
        left: 75vw;
    }

    .devlog-prev {
        left: 5vw;
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-height: 850px) { 

    .devlog__heading > h2 {
        font-size: 1.6rem;
        padding: 1.5rem;
    }

    .devlog__content {
        width: 80vw;
        margin-left: 10vw;
        height: 70vh;
        padding: 0;
    }

    .devlog__text {
        width: 74vw;
        height: 60vh;
        padding: 1rem;
        margin: 0;
    }

    .devlog__text p {
        font-size: 1.2rem;
    }

    .devlog__text h4 {
        font-size: 1.6rem;
    }

    .devlog__date {
        padding-right: 0;
    }

    .devlog__date p {
        font-size: 0.8rem;
    }

    .devlog-slides {
        overflow-y: auto;
        width: 78vw;
        height: 60vh;
    }

    .devlog-next, .devlog-prev {
        top: 450%;
    }
    
    .devlog-next {
        left: 90vw;
    }

    .devlog-prev {
        left: 5vw;
    }
}

/* ------------ MEDIA QUERIES (EXTRA SHORT DISPLAYS) ------------- */
@media screen and (max-height: 500px) { 
 
    .devlog__heading > h2 {
        font-size: 1rem;
        padding: 0.8rem;
    }

    .devlog__content {
        width: 80vw;
        margin-left: 10vw;
        height: 70vh;
        padding: 0;
    }

    .devlog__text {
        width: 74vw;
        height: 60vh;
        padding: 0.2rem;
        margin: 0;
    }

    .devlog__text p {
        font-size: 0.8rem;
    }

    .devlog__text h4 {
        font-size: 1rem;
    }

    .devlog__date {
        padding-right: 0;
    }

    .devlog__date p {
        font-size: 0.8rem;
    }

    .devlog-slides {
        overflow-y: auto;
        width: 78vw;
        height: 55vh;
    }

    .devlog-next, .devlog-prev {
        width: 70px;
        padding: 0.5rem;
        font-size: 40px;
        border-radius: 5px;
    }
    
    .devlog-next {
        left: 90vw;
    }

    .devlog-prev {
        left: 0px;
    }

    .devlog-all-dots {
        top: 1rem;
        bottom: 10px;
        margin-bottom: 10px;
    }

    .devlog-dot {
        cursor: pointer;
        height: 0.8rem;
        width: 0.8rem;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        display: inline-block;
        border: 2px solid rgba(48, 48, 48, 0.534);
    }
}