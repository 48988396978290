.features__body {
    padding-top: 5vh;
}

.features__heading {
    background-color: var(--color-red);
    padding: 2rem;
    border-radius: 1rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid var(--color-white);
}

.features__content {
    padding: 0;
    margin-top: 5vh;
    height: 70vh;
    width: 80vw;
    margin-left: 10vw;
    text-align: center;
}

.features__heading > h2 {
    text-align: center;
}

.features__content > h3 {
    margin-bottom: 2rem;
}

.features__content > section {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.slider-active {
    display: inline-block;
    margin: 0;
    height: 100vh;
}

.slider-inactive {
    display: none;
}

.slide-image {
    height: 70vh;
    width: 80vw;
    border-radius: 1rem;
    border: 3px solid var(--color-white);
}

.slider-text-screen {
    position: relative;
    display: inline-block;
    margin-top: -20vh;
}

.slide-text {
    position: relative;
    top: 50%;
    left: 50%;
    color: var(--color-white);
    font-size: 30px;
    height: fit-content;
    width: 60vw;
    background-color: rgba(48, 48, 48, 0.6);
    padding: 1.2rem;
    border-radius: 1.2rem;
    transform: translate(-50%, -80%);
}

.slide-text > h2 {
    margin-bottom: 2rem;
}

/* ARROWS & DOTS */

.prev,
.next {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    top: 250%;
    left: calc(5vw - 35px);
    width: 70px;
    padding: 1rem;
    margin-top: -3rem;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
}

.prev:hover, .next:hover {
    color: var(--color-bg);
    background-color: var(--color-white);
    transition: all 0.5s ease-in;
}

.next {
    left: calc(95vw - 35px);
    border-radius: 5px;
}

.all-dots {
    width: 90%;
    height: 50px;
    margin-left: 5%;
    position: relative;
    display: flex;
    top: -4rem;
    justify-content: center;
    z-index: 200;
}

.all-dots > span {
    margin-left: 15px;
}

.dot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
    border: 2px solid rgba(48, 48, 48, 0.534);
}

.active-dot, 
.dot:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 
    .features__content > section {
        width: 90vw;
    }


    .features__heading {
        padding: 1.2rem;
        border-radius: 1rem;
    }
    
    .features__content {
        padding: 0;
        margin-top: 3rem;
        margin-left: 0;
        height: 70vh;
        width: 100vw;
    }
    
    .features__heading > h2 {
        font-size: 1.6rem;
    }

    .slide-text {
        font-size: 1.2rem;
        height: fit-content;
        width: 70vw;
        max-height: 70vh;
        overflow-y: auto;
    }
    
    .slide-text > h2 {
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
    
    .slide-image {
        height: 70vh;
        width: 80vw;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .slides {
        height: 80vh;
        margin-left: auto;
        margin-right: auto;
    }

    .slider-text-screen {
        margin-top: -50vh;
    }

    .all-dots {
        width: 90vw;
        height: 50px;
        margin-top: -2rem;
        margin-left: auto;
        margin-right: auto;
    }

    .next {
        left: 90vw;
    }

    .prev {
        left: 0;
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 
    .features__content > section {
        width: 90vw;
    }


    .features__heading {
        padding: 1.2rem;
        border-radius: 1rem;
    }
    
    .features__content {
        padding: 2rem;
        margin-top: 0;
        margin-left: -3vw;
        height: 70vh;
        width: 100vw;
    }
    
    .features__heading > h2 {
        font-size: 1.6rem;
    }

    .slide-text {
        font-size: 1.2rem;
        height: fit-content;
        width: 88vw;
        max-height: 70vh;
        overflow-y: auto;
    }
    
    .slide-text > h2 {
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
    
    .slide-image {
        height: 70vh;
        width: 90vw;
        margin-top: 0;
        margin-left: 0;
    }

    .slides {
        height: 80vh;
    }

    .slider-text-screen {
        margin-top: -18vh;
    }

    .all-dots {
        width: 90vw;
        height: 50px;
        margin-top: 8vh;
        margin-left: auto;
        margin-right: auto;
    }

    .next, .prev {
        top: 250%;
    }
    
    .next {
        left: 75vw;
    }

    .prev {
        left: 5vw;
    }
}

/* ------------ MEDIA QUERIES (SHORT DEVICES - HEIGHT SCALING) ------------- */
@media screen and (max-height: 850px) { 
    .slide-text {
        font-size: 0.8rem;
        padding: 0.8rem;
        top: 20%;
    }

    .slider-text-screen {
        margin-top: -30vh;
    }
    
    .slide-text > h2 {
        margin-bottom: 0.8rem;
    }
    
    .features__heading > h2 {
        font-size: 1rem;
    }

    .features__heading  {
        padding: 0.8rem;
    }

    .features__content {
        padding: 0;
        margin-top: 1rem;
        height: 70vh;
        width: 80vw;
        margin-left: 10vw;
        text-align: center;
    }

    .slide-text > h2 {
        font-size: 1.2rem;
    }

    /* ARROWS & DOTS */
    
    .prev,
    .next {
        left: calc(5vw - 40px);
        width: 70px;
        padding: 1rem;
        margin-top: -3rem;
        font-size: 40px;
        border-radius: 5px;
    }

    .next {
        left: calc(95vw - 35px);
        border-radius: 5px;
    }

    .all-dots {
        margin-top: 2vh;
    }
}