.devmap__container {
    background-image: url('../../assets/ResearchStation.jpg');
    background-size: cover;
    padding-top: 15vh;
}

.devmap__content {
    width: fit-content;
    margin: auto;
    padding: 2rem;
    background: var(--color-blue);
    border-radius: 1.2rem;
    border: 3px solid var(--color-white);
    height: fit-content;
    transition: var(--transition);
    color: var(--color-white);
    text-align: center;
}

.devmap__content h2 {
    margin-bottom: 2rem;
}

.devmap__list {
    padding-left: 8rem;
    padding-right: 4rem;
    margin-top: 3rem;
    align-items: center;
}

.devmap__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.8rem;
}

.devmap__list p {
    font-size: 1.2rem;
}

.devmap__list-icon {
    margin-right: 2rem;
    margin-top: 6px;
}

.devmap__legend {
    margin-bottom: 2rem;
    margin-right: 3rem;
    margin-left: 3rem;
    border: 1px solid var(--color-white);
    border-radius: 1rem;
    padding: 1rem 1.5rem;
}

.devmap__legend ul {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.devmap__legend-icon {
    margin-top: 6px;
    margin-right: 1rem;
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 
    .devmap__container {
        background-position-x: 1100px;
    }
    
    .devmap__content {
        width: fit-content;
        padding: 1rem;
        height: fit-content;
    }

    .devmap__list {
        text-align: center;
        padding: 0;
        padding-left: 3rem;
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: auto;
    }
    
    .devmap__list li {
        gap: .4rem;
        margin-bottom: 1rem;
        text-align: left;
    }

    .devmap__legend {
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 
    .devmap__container {
        padding-top: 2vh;
        background-position-x: 700px;
    }
    
    .devmap__content {
        padding: .4rem;
    }
    
    .devmap__content h2 {
        margin-bottom: 0.6rem;
        font-size: 1.2rem;
    }
    
    .devmap__list {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0.6rem;
    }
    
    .devmap__list li {
        gap: .4rem;
        margin-bottom: 0.6rem;
    }
    
    .devmap__list p {
        font-size: 0.8rem;
    }
    
    .devmap__list-icon {
        margin-right: .6rem;
        margin-top: 6px;
    }
    
    .devmap__legend {
        width: 50vw;
        padding: .6rem;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.8rem;
    }

    .devmap__legend ul {
        display: flex;
        flex-direction: column;
        gap: .4rem;
    }
    
    .devmap__legend-icon {
        margin-top: 6px;
        margin-right: .6rem;
    }
    
    .devmap__legend-icon-left {
        margin-right: 1rem;
        margin-top: 6px;
    }
}

/* ------------ MEDIA QUERIES (SHORTER DEVICES) ------------- */
@media screen and (max-height: 850px) { 
    .devmap__container {
        padding-top: 10vh;
    }
    
    .devmap__content {
        padding: .4rem;
    }
    
    .devmap__content h2 {
        margin-bottom: 0.6rem;
        font-size: 1.2rem;
    }
    
    .devmap__list {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0.6rem;
    }
    
    .devmap__list li {
        gap: .4rem;
        margin-bottom: 0.6rem;
    }
    
    .devmap__list p {
        font-size: 0.8rem;
    }
    
    .devmap__list-icon {
        margin-right: .6rem;
        margin-top: 6px;
    }
    
    .devmap__legend {
        width: auto;
        padding: .6rem;
        text-align: center;
        font-size: 0.8rem;
    }

    .devmap__legend ul {
        display: flex;
        flex-direction: column;
        gap: .4rem;
    }
    
    .devmap__legend-icon {
        margin-top: 6px;
        margin-right: .6rem;
    }
    
    .devmap__legend-icon-left {
        margin-right: 1rem;
        margin-top: 6px;
    }
}

/* ------------ MEDIA QUERIES (EXTRA SHORT DEVICES) ------------- */
@media screen and (max-height: 500px) { 
    .devmap__container {
        padding-top: 2vh;
    }
    
    .devmap__content h2 {
        margin-bottom: 0.6rem;
        font-size: 0.9rem;
    }
    
    .devmap__list {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0.6rem;
    }
    
    .devmap__list li {
        gap: .4rem;
        margin-bottom: 0.6rem;
    }
    
    .devmap__list p {
        font-size: 0.5rem;
    }
    
    .devmap__legend {
        width: 50vw;
        padding: .2rem;
        margin-left: auto;
        margin-right: auto;
        font-size: 0.5rem;
    }

    .devmap__legend ul {
        display: flex;
        flex-direction: column;
        gap: .2rem;
    }
    
    .devmap__legend-icon {
        margin-top: 0;
        margin-right: .3rem;
    }
    
    .devmap__legend-icon-left {
        margin-right: 1rem;
        margin-top: 6px;
    }
}