.header__container {
    height: 100vh;
    width: 100vw;
    padding-top: 22vh;
}

.header__swirl {
    text-align:center;
    position: absolute;
    height: auto;
    width: 15vw;
    margin-top: -10vh;
    margin-left: 42.5vw;
}

.header__logo {
    width: 60vw;
    margin-left: 20vw;
    height: auto;
}

.header__body > h2 {
    padding-top: 8rem;
    font-size: 3rem;
    text-align: center;
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 
    .header__container {
        padding-top: 35vh;
    }

    .header__swirl {
        width: 40vw;
        margin-top: -20vh;
        margin-left: 30vw;
    }
    
    .header__logo {
        width: 70vw;
        margin-left: 15vw;
    }
    
    .header__body > h2 {
        padding-top: 6rem;
        font-size: 2rem;
        text-align: center;
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 
    .header__swirl {
        width: 50vw;
        margin-top: 0;
        margin-left: 25vw;
    }
    
    .header__logo {
        width: 90vw;
        margin-left: 5vw;
        margin-top: 0;
    }
    
    .header__body > h2 {
        padding-top: 4rem;
        font-size: 1.4rem;
        text-align: center;
    }
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-height: 600px) { 
 
    .header__body > h2 {
        font-size: .8rem;
    }

    .header__swirl {
        width: 30vw;
        margin-top: -10vh;
        margin-left: 35vw;
    }
}

/* ------------ MEDIA QUERIES (SHORTER DISPLAYS) ------------- */
@media screen and (max-height: 850px) { 
 
    .header__swirl {
        width: 20vw;
        margin-left: 40vw;
    }
}

/* ------------ MEDIA QUERIES (EXTRA SHORT DISPLAYS) ------------- */
@media screen and (max-height: 500px) { 
 
    .header__swirl {
        width: 20vw;
        margin-left: 40vw;
    }

    .header__body > h2 {
        padding-top: 10px;
        font-size: 1.2rem;
        text-align: center;
    }

    .header__logo {
        width: 300px;
        margin-left: calc(50vw - 150px);
        height: auto;
    }

    .header__container {
        padding-top: 40vh;
    }
}