#contact {
    color: var(--color-white);
}

.footer__acknowledgement {
    width: 60vw;
    margin-left: 20vw;
    padding-top: 20vh;
    padding-bottom: 1vh;
    font-size: 2rem;
    text-align: center
}

.footer__socials {
    padding-top: 6vh;
    font-size: 2rem;
    text-align: center;
}

.socials__label {
    padding-bottom: 1vh;
}

.footer__supporters {
    padding-top: 6vh;
    font-size: 2rem;
    text-align: center;
}

.supporters__label {
    padding-bottom: 0vh;
}

.vicscreen__logo {
    width: 30vw;
    margin-left: auto;
    margin-right: auto;
    height: auto;
}

.socials__icons {
    font-size: 5rem;
    display: inline-block;
}

.socials__icons > a {
    padding-left: 2rem;
    padding-right: 2rem;
}

#insta:hover {
    color: #e1306c;
}

#discord:hover {
    color: #7289da;
}

#tiktok:hover {
    color: #69c9d0;
}

#twitter:hover {
    color: #00acee;
}

#facebook:hover {
    color: #3b5998;
}

#minds:hover {
    color: #FED132;
}

hr {
    width: 60vw;
    margin-left: 20vw;
    size: 4;
    color: var(--color-white);
}

/* ------------ MEDIA QUERIES (MEDIUM DEVICES - TABLETS) ------------- */
@media screen and (max-width: 1024px) { 
    
}

/* ------------ MEDIA QUERIES (SMALL DEVICES - PHONES) ------------- */
@media screen and (max-width: 600px) { 
    .footer__acknowledgement {
        width: 70vw;
        margin-left: 15vw;
        padding-top: 15vh;
        padding-bottom: 7.5vh;
        font-size: 1.6rem;
    }
    
    .vicscreen__logo {
        width: 60vw;
    }

    .footer__socials {
        padding-top: 7.5vh;
        font-size: 1.6rem;
    }
    
    .socials__label {
        padding-bottom: 4vh;
    }
    
    .socials__icons {
        font-size: 3.5rem;
    }
    
    .socials__icons > a {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* ------------ MEDIA QUERIES (SHORTER DISPLAYS) ------------- */
@media screen and (max-height: 850px) { 
    .footer__acknowledgement {
        width: 70vw;
        margin-left: 15vw;
        padding-top: 15vh;
        padding-bottom: 7.5vh;
        font-size: 1.6rem;
    }
    
    .footer__socials {
        padding-top: 7.5vh;
        font-size: 1.6rem;
    }
    
    .socials__label {
        padding-bottom: 4vh;
    }
    
    .socials__icons {
        font-size: 3.5rem;
    }
    
    .socials__icons > a {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* ------------ MEDIA QUERIES (EXTRA SHORT DISPLAYS) ------------- */
@media screen and (max-height: 850px) { 
    .footer__acknowledgement {
        font-size: 1rem;
    }
    
    .footer__socials {
        padding-top: 2.5vh;
        font-size: 1rem;
    }
    
    .socials__label {
        padding-bottom: 4vh;
    }
    
    .socials__icons {
        font-size: 2rem;
    }
    
    .socials__icons > a {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}